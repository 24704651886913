// Framework
import Image from 'next/image';

// Components
import { EmployerText } from 'components/selfService/typography/EmployerText';
import FindJobForm from 'components/home/FindJob/FindJobForm';

// Helpers
import HardCodedRecentSearch from 'components/home/FindJob/HardCodedRecentSearch';
import { useAuthContext } from 'components/AuthContext';
import DynamicRecentSearch from 'components/home/FindJob/DynamicRecentSearch';
import { BackgroundGrid } from 'components/home/BackgroundGrid';

/**
 * Component
 */
export default function FindJob(): JSX.Element {
  const { authenticated } = useAuthContext();

  return (
    <BackgroundGrid className="relative z-0 min-h-[412px] w-screen pb-12 pl-6 pr-9 pt-6 font-satoshi md:h-[412px] lg:px-0">
      <div className="mx-auto max-w-3xl">
        <EmployerText
          as="h1"
          className="mb-4 text-[32px] font-bold leading-[42px] -tracking-two-percent text-charcoal-500"
        >
          Find your next job
        </EmployerText>
        <FindJobForm />
        <div>
          <EmployerText
            as="h2"
            size="lg"
            className="mt-10 font-satoshi text-lg font-medium leading-7 -tracking-two-percent text-night-rider lg:mt-0"
          >
            Recent searches
          </EmployerText>
          {!authenticated && (
            <div className="mt-1 flex">
              <HardCodedRecentSearch />
            </div>
          )}
          {authenticated && (
            <div className="mt-1 flex">
              <DynamicRecentSearch />
            </div>
          )}
        </div>
      </div>
    </BackgroundGrid>
  );
}
