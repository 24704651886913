import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timeZone from 'dayjs/plugin/timezone';
dayjs.extend(relativeTime);
dayjs.extend(timeZone);

const customLocale = {
  ...dayjs.Ls.en,
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
};
dayjs.locale(customLocale, undefined, true);

/**
 * Formats the date for a job card.
 *
 * @param {string} date - The date to format.
 * @returns {string} - The formatted date.
 */
const formatCardDate = (date: string): string => {
  const formattedDate = dayjs(date).fromNow();
  return formattedDate;
};

export default formatCardDate;
