import RecommendedComponent from 'components/home/CategoriesCards/RecommendedComponent';
import { LatestActiveJobsQuery } from 'database/types';

type RecommendedSectionProps = {
  recommendedJobs: LatestActiveJobsQuery['job'];
};

const RecommendedSection = ({ recommendedJobs }: RecommendedSectionProps) => (
  <section className="mx-auto w-full max-w-6xl px-6">
    <RecommendedComponent
      className="mb-14"
      title="Latest jobs"
      items={recommendedJobs}
    />
  </section>
);

export default RecommendedSection;
