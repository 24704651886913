import Image from 'next/image';
import { twMerge } from 'tailwind-merge';

interface TailorCardProps {
  imageSrc: string;
  altText?: string;
  title: string;
  description: string;
  imageClass?: string;
}

const TailorCard = ({
  imageSrc,
  altText = '',
  title,
  description,
  imageClass,
}: TailorCardProps) => (
  <div className="flex w-full flex-col items-center justify-center px-8 text-center">
    <div className={twMerge('relative z-0 mx-auto', imageClass)}>
      <Image src={imageSrc} alt={altText} fill />
    </div>
    <p className="mb-1 text-xl font-bold leading-8 -tracking-two-percent text-charcoal-500">
      {title}
    </p>
    <p className="max-w-[220px] text-base font-medium leading-[26px] -tracking-two-percent text-charcoal-400">
      {description}
    </p>
  </div>
);

export default TailorCard;
