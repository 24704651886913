type JobsPostedDateProps = {
  children: React.ReactNode;
};

const JobsPostedDate = ({ children }: JobsPostedDateProps) => (
  <p
    id="job-date"
    className="rounded-sm font-satoshi text-sm font-medium text-charcoal-200"
  >
    {children}
  </p>
);

export default JobsPostedDate;
