import { useEffect } from 'react';
import { useAuthContext } from 'components/AuthContext';
import { usePersistentState } from 'utils/usePersistentState';
import {
  Maybe,
  useUnauthUserSearchQuery,
  useUpdateUnauthSearchUserMutation,
  useUserSearchQuery as useSearchQueryDefault,
  UserSearchQueryResult,
} from 'database/types';

type UseSearchQueryHook = () => Pick<
  UserSearchQueryResult,
  'data' | 'loading' | 'error' | 'refetch'
> & {
  authenticated: boolean;
  unAuthKey?: Maybe<string>;
};

export const useSearchQuery: UseSearchQueryHook = () => {
  const { authenticated, user, initialLoadComplete } = useAuthContext();
  const [unAuthKey, setUnauthKey, removeKey] = usePersistentState<string>(
    '__unauth_search_key'
  );
  const [update] = useUpdateUnauthSearchUserMutation();
  const noKey = !unAuthKey || unAuthKey === 'undefined';
  useEffect(() => {
    async function updateUnauthKey(): Promise<void> {
      if (!user) return;
      try {
        await update({
          variables: { user_id: user.sub, local_id: unAuthKey },
        });
      } finally {
        removeKey();
      }
    }

    if (!initialLoadComplete) return;

    if (authenticated) {
      !noKey && updateUnauthKey();
    } else {
      noKey && setUnauthKey(self.crypto.randomUUID());
    }
  }, [authenticated, unAuthKey, initialLoadComplete]);

  const authSearch = useSearchQueryDefault({
    skip: !authenticated,
    fetchPolicy: 'cache-first',
  });

  const unauthSearch = useUnauthUserSearchQuery({
    variables: {
      local_id: unAuthKey,
    },
    skip: authenticated || noKey,
  });

  const { data, error, loading, refetch } = authenticated
    ? authSearch
    : unauthSearch;

  return {
    authenticated,
    data,
    error,
    loading,
    refetch,
    unAuthKey: noKey ? undefined : unAuthKey,
  };
};
