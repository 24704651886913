import Link from 'next/link';

type Employer = {
  name: string;
  logo: string;
  slug: string;
  jobs_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

type FindEmployerProps = {
  employersJobs: Employer[];
};

const FindEmployer = ({ employersJobs }: FindEmployerProps) => {
  return (
    <section className="mx-auto mb-12 w-full max-w-6xl px-6">
      <div className="flex justify-between">
        <h2 className="mb-4 font-satoshi text-2xl font-bold">
          Find the right employer
        </h2>
        <Link
          href="/directory"
          className="hidden font-satoshi text-lg font-medium leading-6 text-orange md:block"
        >
          View all
        </Link>
      </div>
      <div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          {employersJobs.map((employer: Employer, index: number) => (
            <Link
              passHref
              key={index}
              href={ `/directory/${employer.slug}`}
              className="rounded-md border border-solid border-charcoal-50 bg-cream p-4"
            >
              <div className="flex w-full justify-between">
                <div className="size-16 rounded-sm">
                  <img
                    className="max-h-12 max-w-[120px] object-scale-down"
                    src={`${employer.logo}`}
                    alt=""
                  />
                </div>
                <p className="h-max rounded-sm bg-orange-50 px-2 py-1 font-inter font-medium text-orange">
                  {employer?.jobs_aggregate?.aggregate?.count} jobs
                </p>
              </div>
              <p className="mt-4 font-satoshi text-xl font-bold leading-8 text-charcoal-500">
                {employer.name}
              </p>
            </Link>
          ))}
        </div>
      </div>
      <Link
        href="/jobs"
        className="mx-auto mt-8 flex h-10 w-[191px] items-center justify-center rounded-lg border border-solid border-orange-400 bg-orange px-8 md:hidden"
      >
        <span className="whitespace-nowrap font-satoshi text-lg font-medium leading-[27px] text-white">
          View all
        </span>
      </Link>
    </section>
  );
};

export default FindEmployer;
