import CategoryComponent from 'components/home/CategoriesCards/CategoryComponent';
import LocationComponent from 'components/home/CategoriesCards/LocationComponent';
import { HomePageJobsQuery } from 'database/types';
import { HomePageProps } from 'pages';

type CategorySectionProps = {
  categoryJobs: HomePageJobsQuery['category'];
  locationJobs: HomePageProps['location'];
};

const CategorySection = ({
  categoryJobs,
  locationJobs,
}: CategorySectionProps) => (
  <section className="mx-auto w-full max-w-6xl	 px-6">
    <CategoryComponent
      className="mb-14"
      title="Jobs by Category"
      items={categoryJobs}
    />
    <LocationComponent title="Jobs by Location" items={locationJobs} />
  </section>
);

export default CategorySection;
