import { ArrowRightIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';
import { BackgroundGrid } from 'components/home/BackgroundGrid';

const EmployerSite = () => (
  <BackgroundGrid className="mt-24">
    <div className="mx-auto pb-[193px] pt-[79px] text-center md:pb-[106px] md:pt-[70px]">
      <h2 className="text-center text-3xl font-bold leading-[58px] -tracking-two-percent text-charcoal-500 md:text-4xl">
        Employer Site
      </h2>
      <p className="mb-8 mt-2 flex flex-col items-center justify-center text-xl font-medium leading-8 -tracking-two-percent text-charcoal-400">
        <span className="block">Wanting to post a job ad?</span>
        <span className="block">Learn more about our packages.</span>
      </p>
      <Link
        href="/portal"
        className="mx-auto flex h-10 w-[181px] items-center justify-center gap-2 rounded-lg bg-orange px-6 py-1.5"
      >
        <span className="font-satoshi text-lg font-medium leading-[27px] text-white">
          Learn more
        </span>
        <span className="relative top-px">
          <ArrowRightIcon color="#fff" className="size-5" />
        </span>
      </Link>
    </div>
  </BackgroundGrid>
);

export default EmployerSite;
