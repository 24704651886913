import Image from 'next/image';

const ReviewerItem = () => (
  <div className="-ml-8 pl-12 lg:ml-0 lg:pl-20">
    <div className="relative z-0 mb-14 mt-10">
      <p className="relative z-0 text-xl font-medium leading-[38px]">
        <span className="-ml-4 inline-block text-[40px] text-orange-500">
          “
        </span>
        Having tailored jobs for me pop into my inbox while I worked saved me
        time searching for the right job.
        <span className="absolute bottom-0 right-0 text-[40px] text-orange-500">
          ”
        </span>
      </p>
    </div>
    <div className="flex justify-between">
      <div className="flex gap-3">
        <div>
          <Image
            src="/assets/images/jobs/matt.png"
            alt=""
            width={48}
            height={48}
            className="rounded-full object-cover"
          />
        </div>
        <div>
          <p className="text-lg font-bold leading-[28px] -tracking-two-percent text-charcoal-500">
            Matt Jennings
          </p>
          <p className="text-sm font-medium leading-[22px] -tracking-two-percent text-charcoal-300">
            Electrician
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ReviewerItem;
