import { twMerge } from 'tailwind-merge';
import HomeStayForm from 'components/home/HomeStayUpdated/HomeStayForm';
import ReviewerSlider from 'components/home/HomeStayUpdated/ReviewerSlider';

type HomeStayUpdatedProps = {
  className?: string;
};

const HomeStayUpdated = ({ className }: HomeStayUpdatedProps) => (
  <section
    className={twMerge(
      'relative mx-auto mt-24 w-full bg-cream px-6 py-8 text-center font-satoshi',
      className
    )}
  >
    <div className="mx-auto grid w-full max-w-6xl	 grid-cols-1 gap-16 rounded-lg px-4 py-8 lg:grid-cols-2 lg:gap-0">
      <div
        className="order-2 rounded-xl bg-white p-8 lg:order-1"
        style={{
          boxShadow:
            '0px 3px 7px 0px #F7BFA11C, 0px 10px 13px 0px #F7BFA117, 0px 23px 17px 0px #F7BFA10F, 0px 42px 20px 0px #F7BFA105, 0px 65px 22px 0px #F7BFA100',
        }}
      >
        <p className="mx-auto max-w-72 text-[26px] font-bold leading-[36px] -tracking-two-percent">
          Stay notified when a new role is posted.
        </p>
        <HomeStayForm />
      </div>
      <div className="relative z-0 order-1 h-max pt-8 text-left lg:order-2">
        <div>
          <div>
            <div className="pl-0 lg:pl-20">
              <p className="mb-2 text-xl font-bold leading-[32px] -tracking-two-percent text-orange-500">
                Stay updated
              </p>
              <p className="text-[44px] font-bold leading-[58px] -tracking-two-percent text-charcoal-500">
                We have new jobs posted daily
              </p>
            </div>
            <ReviewerSlider />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HomeStayUpdated;
