export const profileCard = {
  imageSrc: '/assets/images/home/profile-picture.png',
  altText: 'Profile Picture',
  title: 'Add Profile Picture',
  description: 'Give us your best shot. Remember good lighting always helps.',
  imageClass: 'size-[85px]',
};

export const skillsCard = {
  imageSrc: '/assets/images/home/relevant-skills.png',
  altText: 'Relevant Skills',
  title: 'Add in relevant skills',
  description:
    'Tell us the skills you have picked up on related jobs or similar style sites.',
  imageClass: 'h-[85px] w-[210px]',
};

export const licensesCard = {
  imageSrc: '/assets/images/home/licenses.png',
  altText: 'Licenses',
  title: 'Add Licenses',
  description:
    'Any specific licences or papers for particular trade roles that you hold.',
  imageClass: 'h-[85px] w-[106px]',
};
