import Link from 'next/link';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import CardsManager from 'components/home/TailorProfile/CardsManager';

const TailorProfile = () => (
  <section className="mx-auto mt-16 w-full max-w-[1136px] px-6 font-satoshi">
    <h2 className="mb-8 font-satoshi text-[26px] font-bold leading-[42px] -tracking-two-percent">
      Tailor your profile, get hired.
    </h2>
    <CardsManager />
    <Link
      href="/register"
      className="mx-auto mt-[54px] flex w-max items-center gap-2 rounded-lg border border-solid border-orange px-6 py-2"
    >
      <span className="text-lg font-medium leading-[27px] text-orange-500">
        Register
      </span>
      <ArrowRightIcon width={16} height={16} color="#F17732" />
    </Link>
  </section>
);

export default TailorProfile;
