type JobsNameProps = {
  children: React.ReactNode;
};

const JobsName = ({ children }: JobsNameProps) => (
  <p
    id="job-name"
    className="flex w-full items-center font-satoshi text-sm font-medium text-charcoal-300"
  >
    {children}
  </p>
);

export default JobsName;
