import ReviewerItem from 'components/home/HomeStayUpdated/ReviewerItem';

const ReviewerSlider = () => {
  // const sliderRef = useRef<Slider>(null);
  // const [currentIndex, setCurrentIndex] = useState(0);

  // const settings = {
  //   dots: false,
  //   arrows: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   beforeChange: (current: number, next: number) => setCurrentIndex(next),
  // };

  // const previousSlide = () => {
  //   if (sliderRef.current) {
  //     sliderRef.current.slickPrev();
  //   }
  // };

  // const nextSlide = () => {
  //   if (sliderRef.current) {
  //     sliderRef.current.slickNext();
  //   }
  // };

  return (
    <div className="relative z-0">
      <ReviewerItem />
      {/* <Slider {...settings} ref={sliderRef}>
        <ReviewerItem />
        <ReviewerItem />
      </Slider> */}
      {/* <div className="absolute bottom-0 right-0 flex gap-4">
        <button
          onClick={previousSlide}
          className={currentIndex === 0 ? 'pointer-events-none opacity-0' : ''}
        >
          <StayArrow />
        </button>
        <button
          onClick={nextSlide}
          className={currentIndex === 2 ? 'pointer-events-none opacity-0' : ''}
        >
          <StayArrow className="-scale-x-100" />
        </button>
      </div> */}
    </div>
  );
};

export default ReviewerSlider;
