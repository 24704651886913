// Components
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';

// Helpers
import { makeJobsPath } from 'utils';
const hardcodeRecentSearches = ['construction', 'plumbing', 'roading'];

/**
 * Component
 */
export default function HardCodedRecentSearch(): JSX.Element {
  return (
    <ul className="flex flex-wrap gap-3">
      {hardcodeRecentSearches?.map((category, index) => {
        const jobsQuery = makeJobsPath({
          // keywords: undefined,
          category,
          location: [],
        });

        return (
          <li
            className="flex items-center rounded bg-albino-50 p-2"
            key={index}
          >
            <Link
              href={{
                pathname: '/[...jobs]',
                query: {
                  jobs: jobsQuery,
                },
              }}
              className="flex"
            >
              <span className="mr-1">
                <MagnifyingGlassIcon fill="#F17732" className="size-5" />
              </span>
              <p className="font-inter text-base font-medium leading-5 text-orange">
                <span className="capitalize">{category}</span> jobs in NZ
              </p>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
