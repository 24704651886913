import Image from 'next/image';
import Link from 'next/link';
export type WorkType = {
  __typename: string;
  job_url: string;
  image: [
    {
      __typename: string;
      url: string;
    },
  ];
};
type FeaturedJobProps = {
  weekjobs: WorkType[];
};

const FeaturedJob = ({ weekjobs }: FeaturedJobProps) => {
  return (
    <section className="mx-auto w-full px-6 pt-16 lg:max-w-6xl	">
      <h2 className="mb-2.5 font-satoshi text-2xl font-bold leading-[38px] -tracking-two-percent md:mb-[21px] md:text-[26px] md:leading-[42px]">
        Featured jobs of the week
      </h2>
      <div className="relative z-0 mx-auto mb-16 grid size-full max-w-[500px] grid-cols-1 gap-6 sm:grid-cols-2 md:gap-10 lg:max-w-6xl	 lg:grid-cols-4">
        {weekjobs.map((job, index) => (
          <Link
            key={index}
            className="relative z-0 mx-auto size-full max-w-[320px] rounded-lg border-none sm:size-[240px]"
            href={job.job_url}
            passHref
          >
            <Image
              src={job.image[0].url}
              alt=""
              className="size-full rounded-lg object-cover"
              width={240}
              height={240}
            />
          </Link>
        ))}
      </div>
    </section>
  );
};

export default FeaturedJob;
