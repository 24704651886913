import dynamic from 'next/dynamic';
import TailorCard from 'components/home/TailorProfile/TailorCard';
import {
  licensesCard,
  profileCard,
  skillsCard,
} from 'components/home/TailorProfile/content';
import useIsMobile from 'utils/hooks/useIsMobile';
const TailorSlider = dynamic(() => import('./TailorSlider'));

const CardsManager = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className="category-list">
        <TailorSlider>
          <TailorCard {...profileCard} />
          <TailorCard {...skillsCard} />
          <TailorCard {...licensesCard} />
        </TailorSlider>
      </div>
    );
  }

  return (
    <div className="flex">
      <TailorCard {...profileCard} />
      <TailorCard {...skillsCard} />
      <TailorCard {...licensesCard} />
    </div>
  );
};

export default CardsManager;
