type CategoryTitleProps = {
  children: string;
};

const CategoryTitle = ({ children }: CategoryTitleProps) => (
  <p
    id="job-title"
    className="text-nero font-satoshi text-xl font-bold leading-8 -tracking-two-percent"
  >
    {children}
  </p>
);

export default CategoryTitle;
