import { useCallback, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import { User_Search } from 'database/types';
import { useAuthenticatedAxios } from 'utils/stripe';
import { useSearchQuery } from './useSearchQuery';

type SearchType = Pick<
  User_Search,
  | 'search'
  | 'location'
  | 'category'
  | 'employer_slug'
  | 'from'
  | 'to'
  | 'job_type'
>;

export const useSearchHistory = () => {
  const axios = useAuthenticatedAxios();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    authenticated,
    data,
    loading: l,
    // refetch,
    unAuthKey,
  } = useSearchQuery();

  const search = useCallback(
    async (newSearch: SearchType) => {
      if (l || loading) return;
      setLoading(true);
      try {
        if (!authenticated && !unAuthKey) throw Error('Missing unAuthKey key');
        axios.post('/api/search', {
          ...newSearch,
          unauth_id: authenticated ? null : unAuthKey,
        });
        // refetch();
      } catch (err) {
        captureException(err);
      } finally {
        setLoading(false);
      }
    },
    [loading, l]
  );

  return {
    search,
    history: data?.user_search,
    loading: l,
  };
};
