import Link from 'next/link';
import CategoryTitle from 'components/home/CategoriesCards/CategoryTitle';
import JobsQuantity from 'components/home/CategoriesCards/JobsQuantity';
import { HomePageProps } from 'pages';

type LocationComponentProps = {
  title: string;
  items: HomePageProps['location'];
  className?: string;
};

const LocationComponent = ({
  title,
  items,
  className,
}: LocationComponentProps) => {
  const itemsToShow = items.slice(0, 6);

  return (
    <div className={className}>
      <h2 className="mb-4 font-satoshi text-2xl font-bold">{title}</h2>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        {itemsToShow.map((item, index) => (
          <Link
            key={index}
            className="flex w-full flex-wrap items-center justify-between rounded-md border border-solid border-charcoal-50 bg-cream p-4"
            passHref
            href={`/jobs/all/${item.slug}`}
          >
            <CategoryTitle>{item.region}</CategoryTitle>
            {/** Request 17th May */}
            {/* <JobsQuantity>{item.count} jobs</JobsQuantity> */}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default LocationComponent;
