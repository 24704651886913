// Third Parties
import { Controller, useForm } from 'react-hook-form';

// Components
import { captureException } from '@sentry/nextjs';
import { UpdatedInput } from 'components/forms/UpdatedFormInput';
import HubspotSubmit from 'utils/HubspotSubmit';
import { useMessages } from 'components/Messages';
import { UpdatedMultiSelect } from 'components/forms/newDesign/UpdatedMultiSelect';

// Types
import { EnumType } from 'types/utils';

// Helpers
import { useGetEnumCategoriesQuery } from 'database/types';
import { Button } from 'components/Button';

/**
 * Component
 */
const HomeStayForm = () => {
  const { handleSubmit, register, control } = useForm<any>();
  const { data: catData, loading: cLoading } = useGetEnumCategoriesQuery();

  const { alert } = useMessages();

  const onSubmit = async (data: any) => {
    try {
      const portalId = '20147764';
      const formGuid = '5fd91072-791e-4ec1-926c-3a9b73c5b5a2';
      const fields = [
        {
          name: 'firstname',
          value: data.name,
        },
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'category',
          value: data.category?.join('; '),
        },
      ];

      await HubspotSubmit({ portalId, formGuid, fields });

      alert({
        key: 'NewsLetter',
        type: 'success',
        title: 'You are subscribed',
        dismissable: true,
        duration: 3000,
      });
    } catch (error: any) {
      alert({
        key: 'NewsLetter',
        type: 'error',
        title: error.message,
        dismissable: true,
      });
      captureException(error);
    }
  };

  if (cLoading) {
    return <></>;
  }

  return (
    <form
      id="stay-informed-landing"
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-center justify-center py-6"
    >
      <UpdatedInput
        className="mb-2 w-full text-left"
        {...register('name')}
        label="Name*"
        placeholder="Name"
        required
        customLabel="text-charcoal-500 font-bold text-sm leading-5 pb-2 block"
      />
      <UpdatedInput
        className="mb-2 w-full text-left"
        {...register('email')}
        label="Email Address*"
        placeholder="Email Address*"
        customLabel="text-charcoal-500 font-bold text-sm leading-5 pb-2 block"
        required
      />
      <Controller
        control={control}
        defaultValue={[]}
        name="category"
        render={({ field }) => (
          <UpdatedMultiSelect
            value={field.value}
            options={(catData?.enum_category as EnumType[]) ?? []}
            defaultValues={[]}
            label="Add category*"
            name="category"
            onChange={field.onChange}
            className="mx-auto flex w-full flex-col justify-start text-left"
            customLabel="text-charcoal-500 font-bold text-sm leading-5 pb-2 block text-left"
          />
        )}
      />
      <Button
        size="small"
        type="submit"
        className="w-full"
        // className="flex h-12 w-full cursor-pointer items-center justify-center rounded-lg border-none bg-orange"
      >
        {/* <span className="size-max whitespace-nowrap text-lg font-medium leading-[27px] text-white"> */}
        Keep me updated
        {/* </span> */}
      </Button>
    </form>
  );
};

export default HomeStayForm;
