import Link from 'next/link';

// Components
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

// Helpers
import { useSearchQuery } from 'utils/hooks/useSearchQuery';
import { makeJobsPath } from 'utils';

/**
 * Component
 */
export default function DynamicRecentSearch(): JSX.Element {
  const { data } = useSearchQuery();
  const recent_searches = data?.user_search;

  return (
    <ul className="flex flex-wrap gap-3">
      {recent_searches?.map(({ category, location, search = '' }, index) => {
        const jobsQuery = makeJobsPath({
          keywords: search ?? undefined,
          category: category ?? undefined,
          location: location ? [location] : [],
        });

        return (
          <li
            className="flex items-center rounded bg-albino-50 p-2"
            key={index}
          >
            <Link
              href={{
                pathname: '/[...jobs]',
                query: {
                  jobs: jobsQuery,
                },
              }}
              className="flex"
            >
              <span className="mr-1">
                <MagnifyingGlassIcon fill="#F17732" className="size-5" />
              </span>
              <p className="font-inter text-base font-medium leading-5 text-orange">
                {search ?? 'All'}
                {search && category && ' '}
                {category ?? ' ' + category} jobs in{' '}
                {!location || location === 'undefined' ? 'NZ' : location}
              </p>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
