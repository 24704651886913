import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';
import React, { Fragment, ReactNode } from 'react';
import { Loading } from 'components/icons';
import { Errors, LABEL_CLASSES, useFieldError } from '../..';

export type SelectUpdatedOption<T = string | number> = {
  disabled?: boolean;
  value: T;
  label: ReactNode;
};

export type SelectUpdatedProps<T = string | number> = {
  border?: boolean;
  className?: string;
  disabled?: boolean;
  label?: ReactNode;
  loading?: boolean;
  multiselect?: boolean;
  name?: string;
  noValidation?: boolean;
  onChange?: Parameters<typeof Listbox>['0']['onChange'];
  options?: SelectUpdatedOption<T>[];
  placeholder?: string;
  value?: SelectUpdatedOption<T | T[]>['value'];
};

export const SelectUpdated = <T,>({
  className,
  label,
  loading = false,
  multiselect = false,
  name,
  noValidation,
  options,
  onChange,
  placeholder,
  value,
  disabled,
  border = true,
}: SelectUpdatedProps<T>): JSX.Element => {
  const { error } = useFieldError(name);

  const handleChange = (v: any) => {
    let nextValue;
    if (!multiselect) {
      nextValue = v;
    } else {
      nextValue = value instanceof Array ? [...value] : [];
      const indexOf = nextValue?.indexOf(v);
      if (indexOf !== undefined && indexOf > -1) {
        nextValue.splice(indexOf, 1);
      } else {
        nextValue.push(v);
      }
    }
    onChange && onChange(nextValue);
  };

  const getSelected = (s?: typeof value) => {
    if (!(multiselect && s instanceof Array))
      return options?.find((i) => i.value === s)?.label;
    return s.length < 1 ? (
      'Select an option'
    ) : (
      <div className="-mb-2 overflow-hidden">
        {options
          ?.filter((option) => s.includes(option.value))
          .map((option, i) => (
            <div
              key={`${option.value}_${i}`}
              className="mb-2 mr-2 inline-flex items-center rounded-sm bg-orange-200 p-2 text-sm font-medium"
            >
              <span className="whitespace-nowrap">{option.label}</span>
              <button
                type="button"
                className="ml-2 rounded-sm hover:outline-none hover:ring-2 hover:ring-orange-200 focus:outline-none focus:ring-2"
                onClick={() => handleChange(option.value)}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="size-4" aria-hidden="true" />
              </button>
            </div>
          ))}
      </div>
    );
  };

  return (
    <div
      className={twMerge(
        'relative',
        noValidation ? '' : error ? 'mb-2' : 'mb-8',
        className
      )}
    >
      <Listbox value={value} onChange={handleChange} disabled={disabled}>
        {({ open, disabled }) => (
          <div>
            {label && (
              <Listbox.Label className={LABEL_CLASSES} htmlFor={name}>
                {label}
              </Listbox.Label>
            )}
            <div className=" relative text-sm sm:text-base">
              <Listbox.Button
                className={twMerge(
                  'relative w-full cursor-default rounded py-2 pl-6 pr-10 text-left focus:outline-none',
                  border && 'border border-charcoal-50',
                  (value instanceof Array && !!value.length) || value
                    ? ''
                    : 'text-gray text-opacity-50',
                  error && 'border-red-500',
                  disabled ? 'bg-grey-light' : 'bg-white',
                  className
                )}
              >
                {value ? getSelected(value) : placeholder ?? 'Select an option'}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  {loading ? (
                    <Loading className="size-5" aria-hidden="true" />
                  ) : (
                    !disabled && (
                      <ChevronDownIcon className="size-5" aria-hidden="true" />
                    )
                  )}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 focus:outline-none"
                >
                  {options?.map(
                    ({ value: optionValue, label, disabled = false }, i) => (
                      <Listbox.Option
                        disabled={disabled}
                        key={`${optionValue}_${i}`}
                        className={({ active }) =>
                          twMerge(
                            'relative select-none py-2 pl-6 pr-10 text-base',
                            active
                              ? 'cursor-default bg-orange-50 text-orange-500 font-bold'
                              : disabled
                                ? 'cursor-not-allowed bg-gray-100 text-grey'
                                : 'cursor-default'
                          )
                        }
                        value={optionValue}
                        data-test-value={optionValue}
                      >
                        {({ selected }) => {
                          const isSelected = multiselect
                            ? value instanceof Array &&
                              value.includes(optionValue)
                            : selected;
                          return (
                            <>
                              <span
                                className={twMerge(
                                  isSelected ? 'font-medium' : 'font-normal',
                                  'block truncate text-base'
                                )}
                                data-test-selected={isSelected}
                              >
                                {label}
                              </span>

                              {isSelected ? (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-base">
                                  <CheckIcon
                                    className="size-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          );
                        }}
                      </Listbox.Option>
                    )
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
      {!noValidation && <Errors error={error} />}
    </div>
  );
};
