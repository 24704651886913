'use client';

// Third Parties
import { Controller, useForm } from 'react-hook-form';
import { useJitsu } from '@jitsu/nextjs';

// Components
import { useRouter } from 'next/router';
import { UpdatedInput } from 'components/forms/UpdatedFormInput';

// Helpers
import { NZRegions } from 'utils/sampleData';
import { SelectUpdated } from 'components/forms/newDesign/SelectUpdated';

import { JobsPaths, makeJobsPath, makeLocationPath } from 'utils';
import { useSearchHistory } from 'utils/hooks/useSearchHistory';

// Types
type SearchFormData = Pick<JobsPaths, 'keywords'> & { location?: string };

/**
 * Component
 */
const FindJobForm = () => {
  const { control, handleSubmit, register } = useForm<SearchFormData>();
  const router = useRouter();
  const { search } = useSearchHistory();
  const { track } = useJitsu();

  const onSubmit = async ({ keywords, location }: SearchFormData) => {
    await search({ search: keywords, location });
    track('job_search', { keywords, location });
    router.push({
      pathname: '/[...jobs]',
      query: {
        jobs: makeJobsPath({
          keywords,
          location: makeLocationPath({ region: location }),
        }),
      },
      hash: 'results',
    });
  };

  return (
    <form
      id="jobs-search"
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full flex-col items-start justify-center gap-4 font-satoshi lg:h-24 lg:flex-row lg:items-center"
    >
      <UpdatedInput
        className="mb-0 flex h-12 w-full flex-1 items-center justify-center text-charcoal-500 lg:w-max"
        {...register('keywords')}
        placeholder='Job title, eg "builder"'
        required
      />
      <Controller
        name="location"
        control={control}
        render={({ field: { onChange, name, value } }) => (
          <SelectUpdated
            placeholder="Location"
            options={[
              { value: undefined, label: 'Any Location' },
              ...NZRegions.map((region) => ({
                value: region.toLowerCase(),
                label: region,
              })),
            ]}
            className="h-12 w-full flex-1"
            noValidation
            onChange={onChange}
            name={name}
            value={value}
          />
        )}
      />
      <button
        type="submit"
        className="flex h-12 w-full cursor-pointer items-center justify-center rounded-lg border-none bg-orange px-12 lg:w-max"
      >
        <span className="size-min text-lg font-medium leading-[27px] text-white">
          Search
        </span>
      </button>
    </form>
  );
};

export default FindJobForm;
