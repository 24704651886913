import Link from 'next/link';
import CategoryTitle from 'components/home/CategoriesCards/CategoryTitle';
import JobsPostedDate from 'components/home/CategoriesCards/JobsPostedDate';
import JobsName from 'components/home/CategoriesCards/JobsName';
import formatCardDate from 'utils/useFormatCardDate';
import { LatestActiveJobsQuery } from 'database/types';

type RecommendedComponentProps = {
  title: string;
  items: LatestActiveJobsQuery['job'];
  className?: string;
};

const RecommendedComponent = ({
  title,
  items,
  className,
}: RecommendedComponentProps) => {
  const shuffledItems = items.sort(() => Math.random() - 0.5);
  const itemsToShow = shuffledItems.slice(0, 6);

  return (
    <div className={className}>
      <h2 className="mb-4 font-satoshi text-2xl font-bold">{title}</h2>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        {itemsToShow.map((item, index) => (
          <Link
            href={`/jobs/${item.category.value}?jobSlug=${item.slug}`}
            key={index}
            className="flex w-full flex-wrap items-center justify-between rounded-md border border-solid border-charcoal-50 bg-cream p-4"
            passHref
          >
            <CategoryTitle>{item.title}</CategoryTitle>
            {item.created_at && (
              <JobsPostedDate>{formatCardDate(item.created_at)}</JobsPostedDate>
            )}
            {item.title && (
              <JobsName>
                <span>{item.employer?.name}</span>
                <span className="mx-2 flex size-1 rounded-full bg-charcoal-50" />
                <span>{item.location.region}</span>
              </JobsName>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RecommendedComponent;
