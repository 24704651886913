import dynamic from 'next/dynamic';
import { gql } from '@apollo/client';
import _get from 'lodash.get';
import { GetStaticProps } from 'next';
import React, { VFC } from 'react';
import slugify from 'slugify';
import { Layout, PageMeta, Logo } from 'components';
import CMSClient from 'utils/CMSClient';
import { CMSData } from 'utils/cmsData';
import { EmployerSite, FindEmployer, FindJob } from 'components/home';
import FeaturedJob, { WorkType } from 'components/home/FeaturedJob';
import { HomeContact } from 'components/Footer/HomeContact';
import { useAuthContext } from 'components/AuthContext';
import getDBClient from 'database/DBClient';

import {
  HomePageJobsDocument,
  HomePageJobsQuery,
  LatestActiveJobsDocument,
  LatestActiveJobsQuery,
  RegionalActiveJobsDocument,
  RegionalActiveJobsQuery,
} from 'database/types';
import { EnumRegions } from 'types/utils';

const RecommendedSection = dynamic(
  () => import('components/home/RecommendedSection')
);
// const HomeStayUpdated = dynamic(
//   () => import('components/home/HomeStayUpdated')
// );
const NotLoggedComponents = dynamic(
  () => import('components/home/NotLoggedComponents')
);

export type HomePageProps = {
  meta: PageMeta;
  weekjobs: WorkType[];
  location: {
    region: EnumRegions;
    count: number;
    slug: string;
  }[];
  recommendedJobs: LatestActiveJobsQuery['job'];
} & Pick<HomePageJobsQuery, 'category' | any>;

const Home: VFC<HomePageProps> = ({
  meta,
  weekjobs,
  category: categoryJobs,
  location: locationJobs,
  employer: employersJobs,
  recommendedJobs,
}) => {
  const { authenticated, loading } = useAuthContext();

  return (
    <Layout meta={meta}>
      <FindJob />
      <FeaturedJob weekjobs={weekjobs} />
      <FindEmployer employersJobs={employersJobs} />
      {loading ? (
        <div className="flex h-50vh animate-pulse items-center justify-center bg-cream">
          <div className="size-max">
            <Logo color="black" />
          </div>
        </div>
      ) : authenticated ? (
        <RecommendedSection recommendedJobs={recommendedJobs} />
      ) : (
        <NotLoggedComponents
          categoryJobs={categoryJobs}
          locationJobs={locationJobs}
        />
      )}
      <EmployerSite />
      <HomeContact />
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async (context: any) => {
  const token = context?.preview ? context.previewData?.token : undefined;

  const GET_CONTENT = await CMSClient(token).query<CMSData<HomePageProps>>({
    query: gql`
      query NewHomePage {
        entries(title: "home") {
          ... on home_home_Entry {
            meta {
              ... on meta_metaContent_BlockType {
                metaTitle
                metaDescription
              }
            }
            weekjobs {
              ... on weekjobs_jobs_BlockType {
                job_url
                image @transform(handle: "small") {
                  url
                }
              }
            }
          }
        }
      }
    `,
  });

  const {
    data: { category, employer_list },
  } = await getDBClient().query<HomePageJobsQuery>({
    query: HomePageJobsDocument,
  });

  const employerRandom = [...employer_list]
    .sort(() => 0.5 - Math.random())
    .slice(0, 3);

  const { data } = await getDBClient().query<RegionalActiveJobsQuery>({
    query: RegionalActiveJobsDocument,
  });

  const location: HomePageProps['location'] = [];

  Object.keys(data).map((i: string) => {
    const region = EnumRegions[i as keyof typeof EnumRegions];
    const locationData = _get(data, i, {});
    location.push({
      region,
      slug: slugify(region, { lower: true }),
      count: _get(locationData, 'aggregate.count', 0),
    });
  });

  const {
    data: { job: recommendedJobs },
  } = await getDBClient().query<LatestActiveJobsQuery>({
    query: LatestActiveJobsDocument,
  });

  const cmsData = GET_CONTENT.data.entries[0];
  const pageData: HomePageProps = {
    meta: cmsData.meta[0] ?? null,
    weekjobs: cmsData.weekjobs,
    category,
    location: location.sort((a, b) =>
      a.count > b.count ? -1 : a.count < b.count ? 1 : 0
    ),
    employer: employerRandom,
    recommendedJobs,
  };

  return {
    props: pageData,
    revalidate: 1,
  };
};

export default Home;
